import React from "react";
import Img from "gatsby-image";
import Modal from "../modal/Modal";
import MyCarousel from "../common/MyCarousel";
import {VideoType} from "../projects/BannerSection";

const GalleryModal = ({slideIndex = 0, onClose, images = [], videos = []}) => {
    const count = images.length + videos.length

    if (typeof slideIndex !== "number") {
        return null;
    }

    return (
        <Modal id="gallery-modal" contentClass="modal-carousel-con" isOpen>
            <button
                onClick={onClose}
                className="btn btn-carousel modal-carousel-close flex-center"
            >
                <CloseIcon/>
            </button>
            <MyCarousel
                slideIndex={slideIndex}
                renderBottomCenterControls={null}
            >
                {images.map((img, idx) => (
                    <div className="text-center" key={idx}>
                        <Img
                            className="modal-carousel-img mb-2"
                            fluid={img}
                            imgStyle={{
                                objectFit: "contain"
                            }}
                            alt=""
                        />
                        <p className="text-light">
                            {idx + 1} / {count}
                        </p>
                    </div>
                ))}
                
                {videos.map((video, idx) => (
                    <div className="text-center" key={idx}>
                        <div className="modal-video mb-2">
                            <VideoType idx={idx} slideIndex={slideIndex} {...video}/>
                        </div>
                        <p className="text-light">
                            {images.length + idx + 1} / {count}
                        </p>
                    </div>
                ))}
            </MyCarousel>
        </Modal>
    );
};

const CloseIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    );
};

export default GalleryModal;
