import image1 from '../../../images/csr-activites/1.jpg'
import image2 from '../../../images/csr-activites/2.jpg'
import image3 from '../../../images/csr-activites/3.jpg'
import image4 from '../../../images/csr-activites/4.jpg'
import image5 from '../../../images/csr-activites/5.jpg'
import image6 from '../../../images/csr-activites/6.jpg'
import image7 from '../../../images/csr-activites/7.jpg'
import image8 from '../../../images/csr-activites/8.jpg'
import image9 from '../../../images/csr-activites/9.jpg'
import image10 from '../../../images/csr-activites/10.jpg'

export const csrdata = [
  {
    gallery2: [
      {
        title: "",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image5,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image6,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image7,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image8,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image9,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: image10,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
    ],
  }
]