import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Galleries from "./Galleries";

function OngoingGallery() {
  const data = useStaticQuery(graphql`
    {
      allOngoingJson(
        filter: { is_active: { eq: true } }
        sort: { order: ASC, fields: order }
        limit: 10
      ) {
        edges {
          node {
            title
            sections {
              title
              gallery {
                title
                images {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = [];

  data.allOngoingJson.edges.forEach(({ node }) => {
    node.sections.forEach(item => {
      if (Array.isArray(item.gallery)) {
        images.push({
          title: `${node.title}${item.title ? ` - ${item.title}` : ""}`,
          gallery: item.gallery
        });
      }
    });
  });

  return <Galleries data={images} />;
}

export default OngoingGallery;
