import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Galleries from "./Galleries";
import { csrdata } from "../projects/csr-activities/csr-data";

function CSRActivities() {
  const images = [];

  csrdata.forEach((item) => {
    if (Array.isArray(item.gallery2)) {
      images.push({
        title: `${item.title ? `${item.title}` : ""}`,
        gallery: item.gallery2,
      });
    }
  });

  return <Galleries data={images} />;
}

export default CSRActivities;
