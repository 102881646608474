import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Galleries from "./Galleries";
import { ongoingdata } from "../projects/commercial/OngoingData";

function OngoingGallery() {
  const data = useStaticQuery(graphql`
    {
      allOngoingJson(
        filter: { is_active: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            title
            sections {
              title
              gallery {
                title
                images {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = [];

  ongoingdata.forEach((item) => {
    if (Array.isArray(item.gallery2)) {
      images.push({
        title: `${item.title ? `${item.title}` : ""}`,
        gallery: item.gallery2,
      });
    }
  });

  return <Galleries data={images} />;
}

export default OngoingGallery;
